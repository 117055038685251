<template>
	<div>
		<div class="container-fluid py-5">
			<div class="row">
				<div class="col-6 col-sm-6 col-lg-6 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-body">
							<h1>New Promo Code</h1>

							<form @submit.prevent="createPromoCode">
								<div class="form-group">
									<label for="" class="form-label">Name of Campaign</label>
									<input type="text" v-model.trim="newPromoCode.name" class="form-control"
										placeholder="" />
								</div>
								<div class="form-group">
									<label for="" class="form-label">Promo Code</label>
									<input type="text" v-model.trim="newPromoCode.promo_code" class="form-control"
										placeholder="Enter a custom promo code" />
								</div>
								<div class="form-group">
									<label for="" class="form-label">Description of promo code Campaign</label>
									<textarea v-model.trim="newPromoCode.description" class="form-control"
										rows="2"></textarea>
								</div>

								<div class="form-group">
									<label for="" class="form-label">How many times should the promo code generated for this
										campaign be used?</label>
									<div>
										<div>
											<label for="" class="form-label"><input type="radio" name="promo_code_usages" />
												<div class="d-inline-block ml-2">
													<div class="input-group input-group-sm">
														<input type="number" class="form-control"
															v-model.number="newPromoCode.max_number_of_uses"
															placeholder="e.g 2" min="0" />
														<div class="input-group-append">
															<span class="input-group-text">times</span>
														</div>
													</div>
												</div>
											</label>
										</div>
										<div>
											<label for="promo_code_usages_unlimited" class="form-label"><input type="radio"
													name="promo_code_usages" id="promo_code_usages_unlimited"
													v-model.number="newPromoCode.max_number_of_uses" value="0" />
												Unlimited
											</label>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label">How do you want to reward the customers that use this
										referral code?</label>
									<select name="reward_kind" v-model="newPromoCode.reward_kind" class="custom-select">
										<option value="wallet_credit">
											Credit Customer's Wallet
										</option>
										<option value="flat_rate_discount_on_trips">
											Discount Customer's Trip by an Amount
										</option>
										<option value="percentage_discount_on_trips">
											Discount Customer's Trip by a Percentage
										</option>
									</select>
								</div>

								<div class="form-group">
									<label for="" class="form-label">{{
										rewardAmountLabel
									}}</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">{{
												rewardAmountLabelSymbol
											}}</span>
										</div>
										<input type="number" v-model.number="newPromoCode.reward_amount"
											class="form-control" placeholder="" />
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label">Which customers can use this referral code?</label>
									<select name="reward_kind" v-model="newPromoCode.customer_constraint"
										class="custom-select">
										<option value="none">All Customers</option>
										<option value="new_customer">Only New Customers</option>
										<option value="select_users">Select Users</option>
										<option value="select_corporates">Select Corporates</option>
									</select>
								</div>

								<div class="form-group" v-if="newPromoCode.customer_constraint == 'select_corporates'">
									<label for="">Select corporates that promo code applies to</label>
									<multiselect v-model="newPromoCode.customer_constraint_options"
										id="select_corporates_input" :custom-label="corporateName" track-by="id"
										placeholder="Type to search" open-direction="bottom" :options="corporatesList"
										:multiple="true" :searchable="true" :loading="isLoading" :internal-search="false"
										:clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="10"
										:limit-text="limitText" :show-no-results="false" :hide-selected="true"
										@search-change="asyncFindCorporates">
										<template slot="clear">
											<div class="multiselect__clear"
												v-if="newPromoCode.customer_constraint_options?.length"
												@mousedown.prevent.stop="newPromoCode.customer_constraint_options = []">
											</div>
										</template><span slot="noResult">Oops! No corporates found. Consider changing the
											search
											query.</span>
									</multiselect>
								</div>

							<div class="form-group" v-if="newPromoCode.customer_constraint == 'select_users'">
									<label for="">Select users that promo code applies to</label>
									<multiselect v-model="newPromoCode.customer_constraint_options" id="select_users_input"
										:custom-label="userName" track-by="id" placeholder="Type to search"
										open-direction="bottom" :options="usersList" :multiple="true" :searchable="true"
										:loading="isLoading" :internal-search="false" :clear-on-select="false"
										:close-on-select="false" :options-limit="300" :limit="10" :limit-text="limitText"
										:show-no-results="false" :hide-selected="true" @search-change="asyncFindUsers">
										<template slot="clear">
											<div class="multiselect__clear"
												v-if="newPromoCode.customer_constraint_options?.length"
												@mousedown.prevent.stop="newPromoCode.customer_constraint_options = []">
											</div>
										</template><span slot="noResult">Oops! No users found. Consider changing the search
											query.</span>
									</multiselect>
								</div>

								<div class="form-group">
									<label for="" class="form-label">When should this referral code expire?</label>
									<div>
										<div>
											<label for="" class="form-label"><input type="radio" value="1"
													id="should_expire_yes" name="should_expire" />
												<div class="d-inline-block ml-2">
													<v-datepicker v-model="newPromoCode.expires_at"></v-datepicker>
												</div>
											</label>
										</div>
										<div>
											<label for="should_expire_no" class="form-label"><input type="radio"
													name="should_expire" id="should_expire_no"
													:checked="newPromoCode.expires_at == null"
													@click="newPromoCode.expires_at = null" value="0" />
												Never expires
											</label>
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="" class="form-label">Apply promo code to beneficiaries immediately after
										creating?</label>
									<div>
										<div>
											<div>
												<label for="should_apply_immediately_to_beneficiaries_yes" class="form-label"><input type="radio"
														name="should_apply_immediately_to_beneficiaries" 
														id="should_apply_immediately_to_beneficiaries_yes"
														:checked="newPromoCode.should_apply_immediately_to_beneficiaries == true"
														@click="newPromoCode.should_apply_immediately_to_beneficiaries = true" 
														value="1" />
													Yes
												</label>
											</div>
											<div>
												<label for="should_apply_immediately_to_beneficiaries_no" class="form-label"><input type="radio"
														name="should_apply_immediately_to_beneficiaries" 
														id="should_apply_immediately_to_beneficiaries_no"
														:checked="newPromoCode.should_apply_immediately_to_beneficiaries == false"
														@click="newPromoCode.should_apply_immediately_to_beneficiaries = false" value="0" />
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group">
									<button :disabled="sendingRequest" type="submit" class="btn btn-lg btn-primary">
										{{
											sendingRequest
											? 'Creating Promo Code..'
											: 'Create Promo Code'
										}}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import promoCode from '@/api/promo_codes'
import Multiselect from 'vue-multiselect'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			corporatesList: [],
			usersList: [],
			shouldRewardOwners: 0,
			sendingRequest: false,
			newPromoCode: {
				name: '',
				description: '',
				customer_constraint: 'none',
				customer_constraint_options: null,
				owner_type: 'admin',
				owner_id: 1,
				expires_at: null,
				max_number_of_uses: 0,
				reward_amount: 0,
				reward_kind: 'wallet_credit',
				should_owner_benefit: false,
				promo_code: '',
				should_apply_immediately_to_beneficiaries: false
			},
			isLoading: false
		}
	},
	mounted() {
		if (this.currentUser && this.currentUser.role) {
			this.newPromoCode.owner_type = 'staff'
		}
	},
	watch: {
		'newPromoCode.customer_constraint': function (newval, oldval) {
			this.newPromoCode.customer_constraint_options = []
			if (
				newval !== 'select_users' &&
				newval !== 'select_corporates'
			) {
				this.newPromoCode.customer_constraint_options = null
			}
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser
		},

		rewardAmountLabelSymbol() {
			if (
				this.newPromoCode.reward_kind == 'wallet_credit' ||
				this.newPromoCode.reward_kind == 'flat_rate_discount_on_trips'
			) {
				return 'NGN'
			}

			if (this.newPromoCode.reward_kind == 'percentage_discount_on_trips') {
				return '%'
			}

			return 'NGN'
		},
		rewardAmountLabel() {
			if (this.newPromoCode.reward_kind == 'wallet_credit') {
				return 'How much do you want to reward customers?'
			}

			if (this.newPromoCode.reward_kind == 'flat_rate_discount_on_trips') {
				return 'How much do you want to remove from the trip?'
			}

			if (this.newPromoCode.reward_kind == 'percentage_discount_on_trips') {
				return 'What percentage discount do you want to give on the trip?'
			}

			return 'How much do you want to reward your customers with?'
		}
	},
	methods: {
		loadCorporates() {
			this.isLoading = true
			this.axios
				.get('/v1/corporates')
				.then((response) => {
					this.corporatesList = response.data.models
					this.isLoading = false
				})
				.catch((e) => {

					this.isLoading = false
					this.corporatesList = []
				})
		},
		asyncFindCorporates(query) {
			this.isLoading = true
			this.axios
				.post('/v1/corporates/search', { name: query })
				.then((response) => {
					this.corporatesList = response.data
					this.isLoading = false
				})
				.catch((e) => {
					this.isLoading = false
					this.corporatesList = []
				})
		},
		asyncFindUsers(query) {
			this.isLoading = true
			this.axios
				.post('/v1/users/search', { user: query })
				.then((response) => {
					this.usersList = response.data
					this.isLoading = false
				})
				.catch((e) => {
					this.isLoading = false
					this.usersList = []
				})
		},
		corporateName({ corporate_name }) {
			return corporate_name
		},
		userName({ fname, lname }) {
			return `${fname} ${lname}`
		},
		async createPromoCode() {
			if (this.sendingRequest) return
			this.sendingRequest = true
			try {
				const payload = this.generateNewPromoCodePayload()
				await promoCode.createPromotion(payload)
				this.$swal({
					icon: 'success',
					title: 'Promo code created',
					text: 'This promo code has been queued to be created',
					showCloseButton: true
				}).then(() => {
					this.$router.push({
						name: 'ListPromoCode'
					})
				})
			} catch (e) {
				this.sendingRequest = false
				let msg = e.toString()
				if (e && e.response && e.response.data && e.response.data.message) {
					msg = e.response.data.message
				}

				this.$swal({
					icon: 'error',
					title: 'Promo code creation failed',
					text: msg
				})
			}
		},
		generateNewPromoCodePayload() {
			this.newPromoCode.owner_id = this.currentUser.id
			const payload = Object.assign(
				{
					customer_benefit: {
						reward_amount: this.newPromoCode.reward_amount,
						reward_kind: this.newPromoCode.reward_kind,
						customer_constraint: 'none',
						customer_constraint_options: null
					}
				},
				this.newPromoCode
			)

			if (
				this.newPromoCode.customer_constraint &&
				this.newPromoCode.customer_constraint != ''
			) {
				payload.customer_benefit.customer_constraint =
					this.newPromoCode.customer_constraint

				payload.customer_benefit.customer_constraint_options =
					this.newPromoCode.customer_constraint_options?.map((i) => i.id)

				if(
					(
						this.newPromoCode.customer_constraint == 'select_users' ||
						this.newPromoCode.customer_constraint == 'select_corporates' 
					) &&
					(
						!payload.customer_benefit.customer_constraint_options || 
						!payload.customer_benefit.customer_constraint_options.length
					)
					) {
					throw new Error('customer constraint of type \'Select Users\' and \'Select Corporates\' requires valid options to be selected')
				}
					
			}

			if (
				Object.keys(payload).indexOf('expires_at') > -1 &&
				!payload.expires_at
			) {
				delete payload.expires_at
			}
			return payload
		}
	}
}
</script>
